<template>
  <b-row
    v-if="finalBreadcrumb || finalTitle"
    class="content-header"
  >
    <!-- Content Left -->
    <b-col
      class="mb-2"
      cols="12"
    >
      <b-row>
        <b-col cols="12">
          <b-breadcrumb
            class="content-header__breadcrumb "
            :class="{
              'mb-1': !!finalTitle
            }"
          >
            <b-breadcrumb-item to="/">
              <feather-icon
                icon="HomeIcon"
                size="16"
                class="align-text-top"
              />
            </b-breadcrumb-item>
            <b-breadcrumb-item
              v-for="item in finalBreadcrumb"
              :key="item.text"
              :active="item.active"
              :to="item.to"
            >
              {{ item.text }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </b-col>
        <b-col
          v-if="finalTitle"
          cols="12"
        >
          <h2 class="content-header-title mb-0">
            {{ finalTitle }}
          </h2>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
  },
  props: {
    breadcrumb: {
      type: Array,
      default: () => [],
    },
    pageTitle: {
      type: String,
      default: '',
    },
  },
  computed: {
    finalTitle() {
      return this.pageTitle ? this.pageTitle : this.$route.meta.pageTitle
    },
    finalBreadcrumb() {
      if (this.breadcrumb.length) {
        return this.breadcrumb
      }
      return this.$route.meta.breadcrumb
    },
  },
}
</script>

<style lang="scss" scoped>
.content-header{
  &__breadcrumb{
    padding-left: 0;
    padding-right: 0;
  }
  .content-header-title{
    border-right: 0 !important;
  }
}
</style>
