<template>
  <b-overlay
    :show="loading"

    spinner-variant="primary"
  >
    <app-breadcrumb
      page-title="Создание кастомного объекта"
      :breadcrumb="breadcrumb"
    />
    <b-card>
      <div class="">
        <b-row v-if="error_message">
          <b-col>
            <small
              class="text-danger d-block mb-1"
            >
              {{ error_message }}
            </small>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Название *"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model.trim="item.name"
                placeholder="Введите название"
                :state="get(validation, 'name[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'name[0]')"
                class="text-danger"
              >
                {{ get(validation, 'name[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Ключ *"
              label-for="key"
              description="Уникальный ключ объекта в рамках сайта, должен состоять не менее чем из 3 и не более чем из 255 символов (a-z, 0-9, -)."
            >
              <b-form-input
                id="key"
                v-model.trim="item.key"
                v-mask="{ regex: '^[a-z0-9]+(?:-[a-z0-9]+)*$', placeholder: '' }"
                placeholder="Введите ключ"
                :state="get(validation, 'key[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'key[0]')"
                class="text-danger"
              >
                {{ get(validation, 'key[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Описание"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model.trim="item.description"
                placeholder="Введите описание"
                :state="get(validation, 'description[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'description[0]')"
                class="text-danger"
              >
                {{ get(validation, 'description[0]') }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col>
            <b-button
              variant="primary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="createItem"
            >
              Создать
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card
      title="Поля"
    >
      <b-alert
        variant="info"
        show
      >
        <div class="alert-body">
          Редактирование доступно после создания объекта.
        </div>
      </b-alert>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BButton, BCard, BCol, BFormGroup, BFormInput, BFormTextarea, BOverlay, BRow, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import get from 'lodash/get'
import { computed, getCurrentInstance, ref } from 'vue'
import AppBreadcrumb from '@/layouts/components/AppBreadcrumb.vue'
import useCrudCreate from '@/composables/useCrudCreate'
import { getWebsite as getWebsiteApi } from '@/services/main-api/websites/externals/websites'
import { createField } from '@/services/main-api/websites/externals/fields'

export default {
  name: 'CreateCustomObjectView',
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    vSelect,
    AppBreadcrumb,
    BFormTextarea,
    BAlert,
  },
  props: {
    siteId: {
      type: [Number, String],
      required: true,
    },
  },
  setup(props) {
    const instance = getCurrentInstance().proxy

    // BEGIN Site
    const site = ref(null)

    const getSite = async () => {
      try {
        site.value = (await getWebsiteApi({
          id: props.siteId,
        })).data.data
      } catch (e) {
        site.value = null
      }
    }
    // END Site

    const resAfterCreate = ref(null)

    const createEntity = async () => {
      const res = await createField({
        website_id: props.siteId,
        name: instance.item.name,
        key: instance.item.key,
        description: instance.item.description,
        type: 'json',
        value: [],
      })

      resAfterCreate.value = res.data.data
    }

    // BEGIN breadcrumb
    const breadcrumb = computed(() => [
      {
        text: 'Сайты',
        to: {
          name: 'sites',
        },
      },
      {
        text: get(site.value, 'name', 'Сайт'),
        to: {
          name: 'sites.external.show',
          params: {
            id: props.siteId,
          },
        },
      },
      {
        text: 'Создание кастомного объекта',
        active: true,
      },
    ])
    // END breadcrumb

    const {
      item,
      loading,
      validation,
      error_message,
      checkValidation,
      clearForm,
      catchValidation,
      createItem,
    } = useCrudCreate({
      item: {
        id: null,
        name: '',
        key: '',
        description: '',
      },
      validation: {
        rules: {
          name: 'required',
          key: 'required',
        },
        customErrorMessages: {
          'required.name': 'Укажите название',
          'required.key': 'Укажите ключ',
        },
      },
      createEntity,
      getMoreData: async () => {
        await getSite()
      },
      getTextAfterCreation: () => 'Объект был создан.',
      runAfterCreation: async () => {
        await instance.$router.push({
          name: 'custom-objects.edit',
          params: {
            id: resAfterCreate.value.id,
          },
        })
      },
    })

    return {
      item,
      loading,
      validation,
      error_message,

      breadcrumb,

      checkValidation,
      clearForm,
      catchValidation,
      createItem,
      get,
    }
  },
  mounted() {
    if (!this.$store.getters['workingMode/mode']) {
      this.$router.push({ name: 'dashboard' })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
