import axiosIns from '@/libs/axios'

export function getField({ id, includes }) {
  return axiosIns.get(`/websites/externals/fields/${id}`, {
    params: {
      includes,
    },
  })
}

export function getFields({
  websiteID, perPage, sortedBy, orderBy, page, search, searchFields, searchJoin, includes,
}) {
  return axiosIns.get('/websites/externals/fields', {
    params: {
      websiteID,
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
      includes,
    },
  })
}

export function createField({
  website_id,
  sort_order,
  name,
  key,
  description,
  type,
  value,
  field_group_id,
}) {
  return axiosIns.post('/websites/externals/fields', {
    website_id,
    sort_order,
    name,
    key,
    description,
    type,
    value,
    field_group_id,
  })
}

export function updateField({
  id,
  sort_order,
  name,
  description,
  value,
}) {
  return axiosIns.patch(`/websites/externals/fields/${id}`, {
    sort_order,
    name,
    description,
    value,
  })
}

export function deleteField(id) {
  return axiosIns.delete(`/websites/externals/fields/${id}`)
}

export default {
  getFields,
  getField,
  createField,
  updateField,
  deleteField,
}
