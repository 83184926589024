import axiosIns from '@/libs/axios'

export function getWebsite({ id, includes }) {
  return axiosIns.get(`/websites/externals/websites/${id}`, {
    params: {
      includes,
    },
  })
}

export function getWebsites({
  projectID, perPage, sortedBy, orderBy, page, search, searchFields, searchJoin, includes,
}) {
  return axiosIns.get('/websites/externals/websites', {
    params: {
      projectID,
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
      includes,
    },
  })
}

export function createWebsite({
  project_id,
  name,
  description,
  mode,
}) {
  return axiosIns.post('/websites/externals/websites', {
    project_id,
    name,
    description,
    mode,
  })
}

export function updateWebsite({
  id,
  name,
  description,
  mode,
}) {
  return axiosIns.patch(`/websites/externals/websites/${id}`, {
    name,
    description,
    mode,
  })
}

export function deleteWebsite(id) {
  return axiosIns.delete(`/websites/externals/websites/${id}`)
}

export default {
  getWebsites,
  getWebsite,
  createWebsite,
  updateWebsite,
  deleteWebsite,
}
